const hambox = document.querySelector(".nav-hamberger");

hambox.addEventListener("click", function (e) {
  const lineOne = document.querySelector(".nav-hamberger-line--one");
  const lineTwo = document.querySelector(".nav-hamberger-line--two");
  const mobileNav = document.querySelector(".nav-box-mobile");
  const mobileNavTxt = document.querySelector(".nav-box-mobile-nav");
  lineOne.classList.toggle("navup");
  lineTwo.classList.toggle("navup");
  mobileNav.classList.toggle("add-height");
  mobileNavTxt.classList.toggle("display-one");
});

/**  */

const displayOne = document.querySelector(".hero-container__txt--one");
const displayTwo = document.querySelector(".hero-container__txt--two");
const displayThree = document.querySelector(".hero-container__txt--three");

const allHeroTxt = document.querySelectorAll(".hero-container__txt");

const heroTxt = document.querySelectorAll(".hero-container__txt");
let txt = 0;

setInterval(heroTxtDisplay, 1000);

function heroTxtDisplay() {
  for (let i = 0; i < heroTxt.length; i++) {
    heroTxt[i].style.display = "none";
  }
  txt++;

  if (txt > heroTxt.length - 1) {
    txt = 0;
  }

  heroTxt[txt].style.display = "block";
}

/** SECON-CONTAINER-OBSERVER */

// const secondContainer = document.querySelector(".second-container");

// const options = {
//   root: null,
//   threshold: 0.6,
// };

// function callback(entries, observer) {
//   const secondTxt = document.querySelectorAll(".second-container__txt");
//   entries.forEach((entry) => {
//     if (entry.isIntersecting) {
//       secondTxt.forEach((txt) => {
//         txt.classList.add("add-transform");
//       });
//       observer.unobserve(entry.target);
//     } else {
//       secondTxt.forEach((txt) => {
//         txt.classList.remove("add-transform");
//       });
//     }
//   });
// }

// const SecondContainerObserver = new IntersectionObserver(callback, options);

// SecondContainerObserver.observe(secondContainer);

//** SCROLL-EFFECT */
const scrollBox = document.querySelector(".second-container__scroll-box");
const scrollTxt = document.querySelectorAll(
  ".second-container__scroll-box-txt"
);
const scrollBoxTwo = document.querySelector(
  ".second-container__scroll-box--two"
);
const scrollTxtTwo = document.querySelectorAll(
  ".second-container__scroll-box-txt--two"
);

const documentHeight = document.documentElement.scrollHeight;
const viewportHeight = document.documentElement.clientHeight;

let oldScroll = window.scrollY;
window.addEventListener("scroll", function (e) {
  const percentageScrolled =
    (scrollY / (documentHeight - viewportHeight)) * 100;

  scrollBox.style.transform = `translateX(-${percentageScrolled}%)`;
  scrollBoxTwo.style.transform = `translateX(${percentageScrolled}%)`;

  if (oldScroll < this.window.scrollY) {
    scrollTxt.forEach((txt) => {
      txt.style.transform = `skew(-20deg)`;
      txt.style.transition = `transform 1s ease`;
    });
  } else {
    scrollTxt.forEach((txt) => {
      txt.style.transform = `skew(20deg)`;
      txt.style.transition = `transform 1s ease`;
    });
  }

  if (oldScroll > this.window.scrollY) {
    scrollTxtTwo.forEach((txt) => {
      txt.style.transform = `skew(-20deg)`;
      txt.style.transition = `transform 1s ease`;
    });
  } else {
    scrollTxtTwo.forEach((txt) => {
      txt.style.transform = `skew(20deg)`;
      txt.style.transition = `transform 1s ease`;
    });
  }

  oldScroll = this.window.scrollY;
});

window.addEventListener("scrollend", function (e) {
  scrollTxt.forEach((txt) => {
    txt.style.transform = `skew(0deg)`;
    txt.style.transition = `transform 1s ease`;
  });

  scrollTxtTwo.forEach((txt) => {
    txt.style.transform = `skew(0deg)`;
    txt.style.transition = `transform 1s ease`;
  });
});

// console.log("minmum", Math.min(posTwo, width));

// const scrollBox = document.querySelector(".second-container__scroll-box");
// const scrollBoxTwo = document.querySelector(
//   ".second-container__scroll-box--two"
// );

// let oldScrollY = window.scrollY;

// window.addEventListener("scroll", function (e) {
//   let pos = window.scrollY - 400;
//   let posTwo = 800 - window.scrollY;
//   if (oldScrollY < window.scrollY) {
//     scrollBox.style.transform = `translateX(${pos}px)`;
//     scrollBoxTwo.style.transform = `translateX(${posTwo}px)`;
//     scrollBoxTwo.style.transition = `transform 2s ease`;
//     scrollBox.style.transition = `transform 2s ease`;
//     const arr = Array.from(scrollBox.children);
//     arr.forEach((item) => {
//       item.style.transform = `skewX(20deg)`;
//       item.style.transition = `transform 1s ease`;
//     });
//     const arrTwo = Array.from(scrollBoxTwo.children);
//     arrTwo.forEach((item) => {
//       item.style.transform = `skewX(20deg)`;
//       item.style.transition = `transform 1s ease`;
//     });
//   } else {
//     scrollBox.style.transform = `translateX(${pos}px)`;
//     scrollBoxTwo.style.transform = `translateX(${posTwo}px)`;
//     scrollBoxTwo.style.transition = `transform 2s ease`;
//     scrollBox.style.transition = `transform 2s ease`;
//     const arr = Array.from(scrollBox.children);
//     arr.forEach((item) => {
//       item.style.transform = `skewX(-20deg)`;
//       item.style.transition = `transform 1s ease`;
//     });
//     const arrTwo = Array.from(scrollBoxTwo.children);
//     arrTwo.forEach((item) => {
//       item.style.transform = `skewX(-20deg)`;
//       item.style.transition = `transform 1s ease`;
//     });
//   }

//   oldScrollY = window.scrollY;
// });

// window.addEventListener("scrollend", function () {
//   const arr = Array.from(scrollBox.children);
//   arr.forEach((item) => {
//     item.style.transform = `skewX(0deg)`;
//     item.style.transition = `transform 1s ease`;
//   });
//   const arrTwo = Array.from(scrollBoxTwo.children);
//   arrTwo.forEach((item) => {
//     item.style.transform = `skewX(0deg)`;
//     item.style.transition = `transform 1s ease`;
//   });
// });

/** WORK MOUSE EFFECT */

window.addEventListener("mousemove", function (e) {
  const customCursor = document.querySelector(".custome-cursor");

  const x = e.clientX;
  const y = e.clientY;

  customCursor.style.left = `${x}px`;
  customCursor.style.top = `${y}px`;
});

const microlyscsHover = Array.from(
  document.querySelectorAll(".third-part__txt")
);

microlyscsHover.forEach((arr) => {
  arr.addEventListener("mouseover", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.add("increase");
  });

  arr.addEventListener("mouseleave", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.remove("increase");
  });
});

// SECOND
const loungeHover = Array.from(document.querySelectorAll(".fourth-part__txt"));

loungeHover.forEach((arr) => {
  arr.addEventListener("mouseover", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.add("increase");
  });

  arr.addEventListener("mouseleave", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.remove("increase");
  });
});

//** HEROPAGE HOVER */
heroTxt.forEach((hero) => {
  hero.addEventListener("mouseover", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.add("increase");
  });

  hero.addEventListener("mouseleave", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.remove("increase");
  });
});

//** SECOND-PART HOVER */
const SecondParTxt = document.querySelectorAll(".second-container__txt");

SecondParTxt.forEach((second) => {
  second.addEventListener("mouseover", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.add("increase");
  });
  second.addEventListener("mouseleave", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.remove("increase");
  });
});

/** SEVEN-PART HOVER */

const SevenTxtOver = document.querySelector(".seven-part__our-service");
SevenTxtOver.addEventListener("mouseover", function () {
  const customCursor = document.querySelector(".custome-cursor__inner");
  customCursor.classList.add("increase");
});

SevenTxtOver.addEventListener("mouseleave", function () {
  const customCursor = document.querySelector(".custome-cursor__inner");
  customCursor.classList.remove("increase");
});

const SevenPart = document.querySelectorAll(".seven-part__txt-overflow-box");
SevenPart.forEach((seven) => {
  seven.addEventListener("mouseover", function (e) {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.add("increase");
  });
  seven.addEventListener("mouseleave", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.remove("increase");
  });
});

//** FOOTER */
const FooterTxt = document.querySelectorAll(".footer__text");
const FooterCompany = document.querySelector(".footer__company-name");

FooterTxt.forEach((footer) => {
  footer.addEventListener("mouseover", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.add("increase");
  });
  footer.addEventListener("mouseleave", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.remove("increase");
  });
});

FooterCompany.addEventListener("mouseover", function () {
  const customCursor = document.querySelector(".custome-cursor__inner");
  customCursor.classList.add("increase");
});

FooterCompany.addEventListener("mouseleave", function () {
  const customCursor = document.querySelector(".custome-cursor__inner");
  customCursor.classList.remove("increase");
});

/** LOGO HOVER */
const logoHover = document.querySelector(".logo-box");
logoHover.addEventListener("mouseover", function () {
  const customCursor = document.querySelector(".custome-cursor__inner");
  customCursor.classList.add("increase");
});

logoHover.addEventListener("mouseleave", function () {
  const customCursor = document.querySelector(".custome-cursor__inner");
  customCursor.classList.remove("increase");
});

// HEADER MOUSE EFFECT

const headerMouse = Array.from(document.querySelectorAll(".header-li"));
const myWork = Array.from(document.querySelectorAll(".five-part__txt-box"));

headerMouse.forEach((head) => {
  head.addEventListener("mouseover", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.add("increase");
  });
  head.addEventListener("mouseleave", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.remove("increase");
  });
});

myWork.forEach((work) => {
  work.addEventListener("mouseover", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.add("increase");
  });
  work.addEventListener("mouseleave", function () {
    const customCursor = document.querySelector(".custome-cursor__inner");
    customCursor.classList.remove("increase");
  });
});

/** HEADER EFFECT */
// const header = document.querySelector(".header");
// const Hero = document.querySelector(".hero-container");

// const HeaderOptions = {
//   root: null,
//   threshold: 1,
// };

// function headerCallBack(entries, observer) {
//   entries.forEach((entry) => {
//     if (!entry.isIntersecting) {
//       header.classList.add("sticky");
//       header.style.transition = `position 2s ease`;
//     } else {
//       header.classList.remove("sticky");
//       header.style.transition = `position 2s ease`;
//     }
//   });
// }

// const HeaderObserver = new IntersectionObserver(headerCallBack, HeaderOptions);

// HeaderObserver.observe(Hero);

//** SIX-PART OBSERVER */
// const sixPart = document.querySelector(".six-part");

// const sixOption = {
//   root: null,
//   threshold: 0.2,
// };

// function sixtPartCallBack(entries, observer) {
//   const headingOne = document.querySelector(".six-part__heading--one");
//   const headingTwo = document.querySelector(".six-part__heading--two");
//   entries.forEach((entry) => {
//     console.log(entry.isIntersecting);
//     if (entry.isIntersecting) {
//       headingOne.classList.add("trans-one");
//       headingTwo.classList.add("trans-two");
//     }
//   });
// }

// const sixPartObserver = new IntersectionObserver(sixtPartCallBack, sixOption);

// sixPartObserver.observe(sixPart);

//** SIX-PART-MOUSE-EFFECT */
// const sixPartCont = document.querySelectorAll(".six-part__main-txt-container");

// sixPartCont.forEach((sixPart) => {
//   sixPart.addEventListener("mousemove", function (e) {
//     const x = e.pageX - sixPart.offsetLeft;
//     const y = e.pageY - sixPart.offsetTop;

//     sixPart.style.setProperty("--x", `${x}px`);
//     sixPart.style.setProperty("--y", `${y}px`);
//   });
// });

window.addEventListener("load", function () {
  const partSevenBox = document.querySelectorAll(
    ".seven-part__txt-overflow-box"
  );
  const partSevenTrans = this.document.querySelectorAll(
    ".seven-part__txt-transform"
  );

  partSevenBox.forEach((box) => {
    const child = box.children;
    box.style.width = `${child.item(0).children[0].clientWidth}px`;
  });
});

window.addEventListener("resize", function (e) {
  const partSevenBox = document.querySelectorAll(
    ".seven-part__txt-overflow-box"
  );
  const partSevenTrans = this.document.querySelectorAll(
    ".seven-part__txt-transform"
  );

  partSevenBox.forEach((box) => {
    const child = box.children;
    box.style.width = `${child.item(0).children[0].clientWidth}px`;
  });
});
